.fontLarge {
   font-size: 20px;
   font-weight: bold;
   line-height: 20px;
}

.fontXLarge {
   font-size: 56px;
   font-size: 52px;
   line-height: 48px;
}

.upgradeItem {
   width: 400px;
}

@media (max-width: 1200px) {
   .card-deck {
      display: block !important;
   }
   .card-deck .card {
      flex: none !important;
      display: block !important;
      margin-bottom: 15px !important;
   }
}



.spinner-enable {
   display: block;
   position: absolute;
   left: 50%;
   top: 50%;
   z-index: 2;
}

.spinner-overlay {
   display: none;
   position: fixed;
   top: 0;
   bottom: 0;
   right: 0;
   left: 0;
   background: rgba(0, 0, 0, 0.6);
   /* z-index: -1; */
}

.button-grey{
   width:150px !important;
   background: grey !important;
}

.button-grey-2{
   background: grey !important;
}

.button-white{
   color: "#4c4b4b";
   background: white !important;
   padding: 0 5!important ;
   border-width: 0px !important;
   border-radius: 5px;
   letter-spacing: 0.01px;
   font-size: 20px;
   font-family: "Sofia Pro Regular Az", Helvetica, Arial, Lucida, sans-serif !important;
   font-weight: 550 !important;
}

.button-white-2{
   color: "#4c4b4b";
   background: white !important;
}