.indexedStep {
  color: black;
  width: 40px;
  height: 40px;
  font-size: 16px;
  background-color: #ebedf0;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #d9e2ed;
}

.indexedStep.accomplished {
  background: linear-gradient(315deg, #e056fd 0%, #000000 74%);
  box-shadow: 0.5px 2px #888888;
  border: 2px solid #d9e2ed;
  font-weight: bolder;
  color: white;
}

.RSPBprogressBar {
  min-height: 13px;
  border: 2px solid transparent;
  border-image: linear-gradient(315deg, #ffffff 0%, #d7e1ec 74%);
  border-image-slice: 1;
  box-shadow: 5px 2px #888888;
}

.create-session-default-file {
opacity: 0;
position: absolute;
z-index: -1;
}

.create-session-select-file {
border: 3px solid transparent;
border-image: linear-gradient(104.21deg, #ff6624 -27.11%, #cdaab1 114.9%);
border-image-slice: 1;
padding: 25px;
width: 60%;
}

.progress_bar_custom {
background-image: linear-gradient(
  45deg,
  rgba(255, 255, 255, 0.15) 25%,
  transparent 25%,
  transparent 50%,
  rgba(255, 255, 255, 0.15) 50%,
  rgba(255, 255, 255, 0.15) 75%,
  transparent 75%,
  transparent
) !important;
background-size: 1rem 1rem !important;
animation: 1s linear infinite progress-bar-stripes;
}

::-webkit-scrollbar {
width: 5px;
}

::-webkit-scrollbar-track {
background: transparent;
border-radius: 10px;
}

::-webkit-scrollbar-thumb {
background: #ffffff;
border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
background: #555;
}

.description-height {
height: calc(1.5em + 0.75rem + 57px) !important;
}
