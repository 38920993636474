$session-card-height: 250px;
$session-card-height-mobile: 300px;
.sessionCardContainer {
  padding: 0;
  border-radius: 10px;
  &__imgContainer {
    width: 100%;
    cursor: pointer;

    &-img {
      width: 100%;
      border-radius: 20px;
      height: $session-card-height;
      object-fit: cover;
      @media (max-width: $breakpoint-mobile) {
        height: $session-card-height-mobile;
      }
    }
  }
  &__textContainer {
    margin-left: 8px;
    margin-top: 8px;
    &-title {
      line-height: 1 !important;
    }
    p {
      margin-bottom: 0.6rem;
    }
  }
  & .container {
    padding: 0;
  }
}
