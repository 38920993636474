.firstbutton {
    padding-top: 15px !important;
    padding-right: 20px !important;
    padding-bottom: 15px !important;
    padding-left: 20px !important;
    color: #ffffff !important;
    border-width: 0px !important;
    border-radius: 5px;
    font-size: 20px;
    font-family: "Sofia Pro Regular Az", Helvetica, Arial, Lucida, sans-serif !important;
    font-weight: 550 !important;
    background-image: linear-gradient(44deg, #f0b800 8%, #8780ef 40%);
}

.secondbutton {
    padding-top: 15px !important;
    padding-right: 20px !important;
    padding-bottom: 15px !important;
    padding-left: 20px !important;
    color: #ffffff !important;
    border-width: 0px !important;
    border-radius: 5px;
    letter-spacing: 0.01px;
    font-size: 20px;
    font-family: "Sofia Pro Regular Az", Helvetica, Arial, Lucida, sans-serif !important;
    font-weight: 550 !important;
    background-image: linear-gradient(78deg, #ec8b80 61%, #f0b800 100%);
}

.main_div {
    background: linear-gradient(to bottom, #f3f2ff 0, #f3f2ff 25%, #e5e5e5 25%, #e5e5e5 87%, #605adf 87%, #605adf 100%);
    margin-left: -30px !important;
    margin-right: -30px;
    margin-top: -20px;
    margin-bottom: -10px;
    @media screen and (max-width: 767px) {
        background: linear-gradient(
            to bottom,
            #f3f2ff 0,
            #f3f2ff 25%,
            #e5e5e5 25%,
            #e5e5e5 93%,
            #605adf 93%,
            #605adf 100%
        );
    }
}

.membership_subtitle {
    color: black;
    font-family: Sofia Pro Regular Az, Helvetica, Arial, Lucida, sans-serif;
    font-style: italic;
    font-size: 22px;
    @media screen and (max-width: 430px) {
        font-size: 19px;
    }
}

.plan_title {
    font-size: 45px !important;
    color: black;
    font-weight: bold !important;
    @media screen and (max-width: 430px) {
        font-size: 40px !important;
    }
}

.main_card {
    border-radius: 15px !important;
    line-height: 26px !important;
    max-width: 338px !important;
}