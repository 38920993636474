.loader-background {
	flex: 0 0 280px;
	background-image: url(../assets/sidebarBg.9d928cdb.jpg);
	background-size: cover;
	background-position: 50%;
	background-repeat: no-repeat;
	overflow: hidden;
	opacity: 0.8;
	transition: width 0.4s ease-in-out;
	color: white;
}
