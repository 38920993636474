
.pp-odd {
  background-color: rgba(0, 0, 0, 0.03);
}

.clientTable .-header {
  background-color: $primary;
  color: white;
  padding: 0.5rem;
}

.clientTable .rt-table .rt-tbody .rt-tr-group .rt-tr .rt-td img {
  object-fit: cover;
}
