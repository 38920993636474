.newSurveyModal{
   display: none; /* Hidden by default */
   position: fixed; /* Stay in place */
   z-index: 3; /* Sit on top */
   left: 0;
   top: 0;
   width: 100%; /* Full width */
   height: 100%; /* Full height */
   overflow: auto; /* Enable scroll if needed */
   background-color: rgb(0, 0, 0); /* Fallback color */
   background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

.newSurveyModal .surveyModalContent {
   padding: 10px 20px;
   background-color: #F0EFFF !important;
   color: black;
   text-align: center;
   border-radius: 10px;
}

.newSurveyModal .modalClose {
   color: #aaa;
   font-size: 28px;
   line-height: 20px;
   font-weight: bold;
   cursor: pointer;
}

.newSurveyModal .ppEmoji {
   font-size: 54px;
   line-height: 54px;
}

.newSurveyModal .ppEmojiButton {
   opacity: 0.25;
   border: 2px solid rgb(102, 102, 102) !important;
   transition: 0.5s;
   background-color: rgb(214, 214, 214);
   width: 100px;
   height: 100px;
   display: flex;
   align-items: center;
   justify-content: center;
   margin: auto;
   cursor: pointer;
}

.newSurveyModal .ppEmojiButton:hover{
   box-shadow: none !important;
   opacity: 1;
}

.newSurveyModal .ppEmoActive {
   opacity: 1;
   box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

.ppBlue {
   color: #007bff;
}
.ppGreen {
   color: #28a745;
}
.ppOrange {
   color: #fd7e14;
}
.ppRed {
   color: #dc3545;
}
.ppGray {
   color: #868e96;
}
.ppPink {
   color: #e83e8c;
}

.newSurveyModal .surveyModalContent .btn{
   width: 66%;
   min-width: 280px;
   border-radius: 62.35px;
   margin: 16px 0;
   border: 0;
}

.newSurveyModal .col-4 .fontSmall{
   margin-top: 10px !important;
   color: grey;
   font-size: 16px !important;
}

.newSurveyModal .btn p{
   margin-bottom: 0;
   margin: 6px 0;
}

.newSurveyModal #continue-btn{
   background: linear-gradient(104.21deg, #FF6624 -27.11%, #CDAAB1 114.9%);
   color: white;
}

.newSurveyModal #prime-btn{
   background: linear-gradient(104.21deg, #00D9FF -27.11%, #CDAAB1 114.9%);
   color: white;
}

.newSurveyModal #done-btn{
   background: linear-gradient(104.21deg, #FF6624 -27.11%, #CDAAB1 114.9%);
   color: white;
}