body {
  /* overflow-x: hidden; */
}
/* div.pp-content {
   margin-left: 320px;
   padding: 1px 16px;

   width: 100%;
   position: fixed;
   height: 100%;
   overflow: auto;
} */

html {
  /* overflow: hidden; */
  height: 100%;
}

body {
  /* height: 100%; */
  height: 100vh;
  overflow: auto;
}

.ppImg {
  vertical-align: top;
}

#sidebar {
  /* position: fixed; */
  overflow-x: hidden;
  /* overflow-y: auto; */
  transition: 0.5s;
}

#sidebarContent {
  width: 240px;
}

.sidebarWidth {
  position: fixed;
  top: 0;
  left: 0;
  width: 240px;
  height: 100%;
}

.portraitInner {
  width: 90px;
  height: 90px;
}

#portrait img {
  border: 3px solid white;
}

#sidebar nav a {
  font-size: 14px;
}

.ppNavIcon,
.nav-link > svg {
  width: 30px !important;
  text-align: center;
}

.navActive {
  background-color: rgba(0, 0, 0, 0.25);
}

#sidebar .nav-link:hover {
  background-color: rgba(0, 0, 0, 0.15);
}

/* #main {
   transition: 0.5s;
} */

.mainWidth {
  transition: 0.5s;
  margin-left: 240px;

  /* Below is what makes it work*/
  /* overflow-y: auto;
  position: absolute;
  height: 100%;
  width: -webkit-fill-available; */
}

.imageContainer {
  width: 100%;
  height: 100%;
  max-height: 400px;
  object-fit: cover;
}

.grayscale {
  filter: grayscale(100%);
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.65;
}

.overlayBlue {
  background-color: $cyan;
}
.overlayGreen {
  background-color: $green;
}
.overlayOrange {
  background-color: $orange;
}
.overlayRed {
  background-color: $red;
}

.imageCaption {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.imageCaptionTitle {
  font-size: 32px;
}

.imageCaptionDesc {
  font-size: 16px;
  opacity: 0.7;
}

.imageCaptionAuthor {
  /*    position: absolute;*/
  /*    bottom: 0;*/
  font-size: 16px;
  opacity: 0.7;
}

.searchAll {
  width: auto;
  border-right: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

#searchBar {
  border-radius: 0;
}

#searchButton {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.filterAll {
  width: auto;
}

.sortAll {
  width: auto;
}

.sessionImage {
  width: 240px;
}

.sessionCaptionDesc {
  font-size: 14px;
  opacity: 0.7;
}

.sessionCaptionAuthor {
  font-size: 12px;
  opacity: 0.7;
}

.ppCard {
  width: 100%;
  height: 100%;
  max-height: 200px;
  object-fit: cover;
}

@media (max-width: 576px) {
}

@media (max-width: 768px) {
  .sidebarWidth {
    width: 0px;
  }

  .mainWidth {
    margin-left: 0px;
  }

  .sidebarOpen {
    width: 240px;
  }
}

@media (max-width: 992px) {
}

@media (max-width: 1200px) {
  /* .mainWidth {
     margin-left: 0px;
  } */
}

// v2 Sidebar

.sidebarSection {
  padding: 15px;
  &__closeIcon {
    align-self: flex-end;
    @media (min-width: $breakpoint-mobile) {
      display: none;
    }
  }
}

.logo-section-sidebar {
  margin-top: 35px;
  display: flex;
  align-items: center;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.07);
  margin-bottom: 20px;
  &__img {
    width: 80px;
    height: 48px;
    margin-right: 12px;
  }

  &__text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #fff;
    & p {
      margin: 0;
    }
    &-normal {
      font-size: 18px;
      font-weight: 100;
      letter-spacing: 0.8px;
      line-height: 0.8;
    }

    &-bold {
      font-size: 20px;
      letter-spacing: 0.4px;
      font-weight: 400;
    }
  }
}

.sidebar-link {
  padding: 8px 16px;
  font-size: 13.5px;
  font-family: sofia-pro, sans-serif;
  font-weight: 300;
  font-style: normal;
  letter-spacing: 1px;
  display: flex;
  align-items: center;
  &__icon {
    margin-right: 16px;
    width: 16px;
  }
}

.navActiveLink {
  background: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(250px);
  border-radius: 5px;
}

.sidebar-seprator {
  border-bottom: 1px solid rgba(255, 255, 255, 0.07);
  margin-top: 10px;
  margin-bottom: 10px;
}

.sidebar-list {
  @media (max-width: 576px) {
    height: 69vh !important;
  }
}
