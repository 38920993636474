.App {
    font-family: sans-serif;
    overflow-y: auto !important;
    max-height: 300px !important;
  }
  
  .dzu-inputLabel {
    color: #35294f !important;
    text-align: center;
  }

@media (max-width: 450px) {
  .dzu-inputLabel {
    padding: 1.5rem !important;
  }
}

  .dzu-inputLabelWithFiles {
    color: #35294f !important;
  }

  ul {
    margin: 0;
  }
  
  .dropzone {
    text-align: center;
    padding: 20px;
    border: 3px dashed #eeeeee;
    background-color: #fafafa;
    color: #bdbdbd;
    margin-bottom: 20px;
  }
  

  .dzu-dropzone {
      overflow: auto !important;
      border: none !important;
      min-height: 70px !important;
  }

  .dzu-inputLabelWithFiles {
      width: 85% !important;
      margin: auto !important;
  }

  progress {
    border: 0px solid #35294f;
    height: 12px;
    border-radius: 25px;
    background: #b9b6b6;
  }
  
  progress::-webkit-progress-value {
    background: #b9b6b6;
    border: 0px solid #35294f;
    border-radius: 25px;
  }
  
  progress::-moz-progress-bar {
    background: lightcolor;
    border: 0px solid #35294f;
    border-radius: 25px;
  }
  
  progress::-webkit-progress-value {
    background: #35294f;
    border: 0px solid #35294f;
    border-radius: 25px;
  }
  
  progress::-webkit-progress-bar {
    background: #b9b6b6;
    border: 0px solid #35294f;
    border-radius: 25px;
  }

  .dzu-previewImage {
      margin-left: 5%;
  }

  .dzu-previewStatusContainer {
    margin-right: 5%;
  }

  .dzu-previewContainer {
    padding : 15px 3% !important;
  }

  .dzu-inputLabel {
    font-family: sofia-pro, sans-serif !important; 
  }

  .progress_bar_custom {
    background-image: linear-gradient(
    45deg,
  rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  ) !important;
  background-size: 1rem 1rem !important;
  animation: 1s linear infinite progress-bar-stripes;
}
