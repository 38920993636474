
html {
  position: relative;
  min-height: 100%;
}
body {

}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 60px; /* Set the fixed height of the footer here */
  line-height: 60px; /* Vertically center the text there */
  background-color: $light;
  transition: 0.5s;

  .copyFont {
    font-size: 14px;
  }

}
