$edit-image-card-height: 140px;

input[type=checkbox] {
    visibility: visible;
}

.edit-image-card {
    position: relative;
    width: $edit-image-card-height;
    height: $edit-image-card-height;
    border-radius: 10px;
    overflow: hidden;
    // padding: 5px;

    &__controls {
        position: relative;
        width: fit-content;
        height: 100%;
        min-height: 140px !important;
        border-radius: 10px;
        display: flex;
    }

    &__image {
        width: 100%;
        height: 100%;
        border-radius: 10px;
        min-height: 140px;
        max-width: 135px;
        max-height: 140px;
        min-width: 135px;
    }
}

._edit_select_Img_Checkbox {
    position: absolute !important;
    z-index: 10;
    float: bottom !important;
    bottom: 0px;
    padding-top: auto !important;
    height: 17px;
    width: 17px;
    margin-top: auto;
    margin-left: 5px;
    margin-bottom: 12px;

    &__label {
        position: relative;
        width: 100%;
        height: 100%;
        min-height: 140px !important;
        top: 0;
        left: 0;
    }
}

._edit_select_Img_Checkbox:checked + label img {
    transform: scale(0.9);
    box-shadow: 0 0 5px #333;
    z-index: -1;
    opacity: 0.4;
}

._edit_select_Img_Delete {
    position: absolute;
    margin-top: 87%;
    margin-left: 82%;
}

._edit_select_Img_Delete svg {
    height: 20px !important;
    width: 20px !important;
}