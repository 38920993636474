.tutorial_div {
    background: white;
    margin-left: -30px !important;
    margin-right: -30px;
    margin-top: -20px;
    margin-bottom: -10px;

    @media (max-width: 580px) {
        margin-left: 0 !important;
        margin-right: 0;
        margin-top: 0;
        margin-bottom: 0;
    }
}

.top_row {
    background: url(../assets/tutorial_back.jpg) no-repeat center center
        fixed;
    margin: 0 !important;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;

    & h1 {
        font-family: sofia-pro, sans-serif;
        font-weight: bolder;
        font-size: 61px;
        line-height: 59px;
    }

    & p {
        font-family: sofia-pro, sans-serif;
        font-weight: 600;
        font-size: 39px;
        line-height: 46px;
    }
}

.middle_row {
    background: url(https://www.positiveprime.com/wp-content/uploads/2021/04/orange-gradient-bg.png) no-repeat center
        center fixed;
    margin: 0 !important;
    & h2 {
        font-family: sofia-pro, sans-serif;
        font-weight: bolder;
        font-size: 40px;
        line-height: 59px;
        color: black;
    }
}

.embed-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
}
.embed-container iframe,
.embed-container object,
.embed-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.embed-container {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    border: 0.5rem solid white;
}
