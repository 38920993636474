.experience__builder__form {
  background: #fff;
  border-radius: 5px;
}
/* 
.dateField label {
  color: white;
}

.dateField input {
  color: white;
}

.dateField label:focus {
  color: white !important;
}

.dateField label.Mui-focused {
  color: white !important;
} */

.MuiFormControlLabel-labelPlacementStart {
  margin-left: 0 !important;
  color: rgba(0, 0, 0, 0.85) !important;
  width: fit-content !important;
}

.experience__builder__table .makeStyles-root-1 {
  overflow: hidden;
}
/* 
.MuiDialog-paperWidthSm {
  max-width: 70vw;
  width: 70vw;
}

.MuiDialog-paperScrollPaper {
  display: flex;
  max-height: calc(100% - 64px);
  flex-direction: column;
  width: max-content;
} */
