$session-cover-height: 350px;
$session-cover-height-mobile: 280px;
.sessionCoverContainer{
    position: relative;
    height: $session-cover-height;
    @media (max-width: $breakpoint-mobile) {
        height: $session-cover-height-mobile;
        text-align: center;
        border-radius: 20px;
    }
    &__img{
        width: 100%;
        height: 100%;
        border-radius: 10px;
    }

    &__textContainer{
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        transform: translate(-50%, -10%);
        padding: 0 32px;
        @media (max-width: $breakpoint-mobile) {
            top: 20px;
            transform: translate(-50%, 0);
        }
        &-button{
            margin-top: 8px;
        }

        .personalize__btn {
            color: #FF6624;
            background: white !important;
        }
    }
}