.imageContainer {
   width: 100%;
   height: 100%;
   object-fit: cover;
}

#carouselShop .carousel-item {
   width: 100%;
   height: calc(100vw * (9/16));
   min-height: 256px;
   max-height: 400px;
}

.grayscale {
   filter: grayscale(100%);
}

.overlay {
   position: absolute;
   top: 0;
   bottom: 0;
   right: 0;
   left: 0;
   width: 100%;
   height: 100%;
   opacity: 0.65;
}

.overlayBlue {
   background-color: #007bff;
}
.overlayGreen {
   background-color: #28a745;
}
.overlayOrange {
   background-color: #fd7e14;
}
.overlayRed {
   background-color: #dc3545;
}

.imageCaption {
   position: absolute;
   top: 50%;
   transform: translateY(-50%);
}

.imageCaptionTitle {
   font-size: 32px;
}

.imageCaptionDesc {
   font-size: 16px;
   opacity: 0.7;
}

.imageCaptionAuthor {
   /*    position: absolute;*/
   /*    bottom: 0;*/
   font-size: 16px;
   opacity: 0.7;
}

#carouselShop .carousel-control-prev,
#carouselShop .carousel-control-next {
   width: 48px;
}

.searchAll {
   width: auto;
   border-right: 0;
   border-top-right-radius: 0;
   border-bottom-right-radius: 0;
}

#searchBar {
   border-radius: 0;
}

#searchButton {
   border-top-left-radius: 0;
   border-bottom-left-radius: 0;
}

.filterAll {
   width: auto;
}

.sortAll {
   width: auto;
}

.sessionImage {
   width: 240px;
}

.sessionCaptionDesc {
   font-size: 14px;
   opacity: 0.7;
}

.sessionCaptionAuthor {
   font-size: 12px;
   opacity: 0.7;
}

.ppCard {
   width: 100%;
   height: 100%;
   max-height: 200px;
   object-fit: cover;
}

.cardMarginBottom {
   margin-bottom: 10px;
}

@media (max-width: 576px) {
}

@media (max-width: 768px) {
   .sidebarWidth {
      width: 0px;
   }

   .mainWidth {
      margin-left: 0px;
   }

   .sidebarOpen {
      width: 240px;
   }
}

@media (max-width: 992px) {
}

@media (max-width: 1200px) {
}


.pp-block-with-text-shop {
   /* autoprefixer: off */
   -webkit-box-orient: vertical;
   /* autoprefixer: on */
   display: block;
   display: -webkit-box;
   height: 4.2em; /* line-height*clamp*font-size 1.4*3*1 */
   line-height: 1.4em;
   -webkit-line-clamp: 3;
   overflow: hidden;
   text-overflow: ellipsis;
}