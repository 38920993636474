.dashboardContainer {
  display: flex;
  min-height: 100vh;
  padding: 0 !important;
}

// .dashboardContainer > * {
//   font-family: "Sofia Pro", sans-serif;
//   color: #fff;
// }
.dashboardContainer > * {
  font-family: sofia-pro, sans-serif;
  color: #fff;
}

.sidebarContainer {
  flex: 0 0 $sidebar-width;
  height: inherit;
  background-image: url(../../assets/sidebarBg.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;
  transition: width 0.4s ease-in-out;
  @media (max-width: 1023px) {
    position: absolute;
    left: 0;
    flex: 0 0 0;
    width: 0px;
  }
}

.sidebarContainerOpen {
  z-index: 100;
  @media (max-width: 1023px) {
    position: fixed;
    top: 0;
    height: 100%;
    left: 0;
    flex: 0 0 $sidebar-width;
    width: $sidebar-width;
  }
}

.mainContainer {
  flex: 1;
  display: flex;
  padding: 10px 30px;
  flex-direction: column;
  background-image: url(../../assets/mainContainerBg.jpg);
  background-size: cover;
  @media (max-width: $breakpoint-mobile) {
    padding: 10px 5px;
    width: 100%;
  }
}

.appbarContainer {
  flex: 0 0 $appbar-height;
  /* Group 69 */
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  margin-bottom: 20px;
  /* Note: backdrop-filter has minimal browser support */
}

.mainLayoutContainer {
  flex: 1;
}

// Global Styles applied to every dashboard Router component

// .main-title {
//   font-style: normal;
//   font-family: sofia-pro extralight, sans-serif;
//   font-weight: 250;
//   letter-spacing: 0.8px;
//   font-size: 32px;
//   &-2 {
//     font-size: 26px;
//   }

//   &-3 {
//     font-size: 22px;
//   }
// }
.main-title {
  font-style: normal;
  font-family: sofia-pro, sans-serif;
  font-weight: 200;
  letter-spacing: 1px;
  font-size: 32px;
  &-2 {
    font-size: 26px;
  }

  &-3 {
    font-size: 22px;
  }
}

.b-text {
  font-weight: 300;
}

.p-text {
  font-style: normal;
  font-weight: 250;
  letter-spacing: 0.4px;
  font-size: 14px;
}

// .x-card-title {
//   font-family: sofia-pro light;
//   font-style: normal;
//   line-height: 22px !important;
//   color: #ffffff;
//   margin-bottom: 5px;
// }

// .x-card-subtitle {
//   font-family: sofia-pro semibold;
//   font-style: normal;
//   font-size: 14px;
//   line-height: 16px;
//   color: #ffffff;
// }
.x-card-title {
  font-family: sofia-pro, sans-serif;
  font-style: normal;
  font-weight: 300;
  line-height: 22px !important;
  color: #ffffff;
  margin-bottom: 5px;
}

.x-card-subtitle {
  font-family: sofia-pro, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
}

.dashed-border {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23213266FF' stroke-width='3' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 9px;
}
