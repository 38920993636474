.login-background {
	background: rgba(0, 10, 84, 0.3) !important;
	mix-blend-mode: normal;
	backdrop-filter: blur(200px) !important;
}
.login-background3 {
	-webkit-flex: 0 0 280px;
	background-image: url(../assets/sidebarBg.9d928cdb.jpg);
	background-size: cover;
	background-position: 50%;
	background-repeat: no-repeat;
	overflow: hidden;
	mix-blend-mode: normal;
	backdrop-filter: blur(200px) !important;
	transition: width 0.4s ease-in-out;
}

.custom-button {
	background: linear-gradient(
		104.21deg,
		#857ff1 -27.11%,
		#cdaab1 114.9%
	) !important;
	border-radius: 86px !important;
	font-family: Gotham;
	font-style: normal;
	font-weight: bold;
	font-size: 16px;
	line-height: 15px;
	letter-spacing: 0.29em;
	text-transform: uppercase;

	color: #ffffff;

	mix-blend-mode: normal;
	opacity: 0.8;
}

.logo-prime {
	margin-top: 10%;
}
.custom-input {
	opacity: 0.5;
	border: 1px solid #ffffff;
	border: 0 !important;
	outline: 0;
	font-family: Gotham;
	color: white !important;
	letter-spacing: 1px !important;
	border: 1px solid #000000;
	text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	border-bottom: 1px solid #ffffff !important;
	background: transparent !important;
}

.errorMessage {
	color: rgba(223, 60, 11, 0.6);
	font-family: sofia-pro, sans-serif;
	font-style: normal;
	font-weight: 300;
	font-size: 12px;
}

.custom-input-label {
	font-family: Gotham;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 13px;
	letter-spacing: 0.29em;
	text-transform: uppercase;

	color: #ffffff;

	opacity: 0.75;
}

.login-wall {
	background: url(../assets/login-background2.jpg) cover !important;
	background-image: url(../assets/login-background2.jpg) !important;
	background-size: cover !important;
	background-position: center center !important;
}

.bottom-text {
	font-family: Gotham;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 13px;
	color: #ffffff;
}

.custom-loader {
	display: flex;
	align-content: center;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	margin: 0;
	padding: 0;
	width: 100%;
	z-index: 100;
	flex: 0 0 280px;
	background-image: url(../assets/sidebarBg.9d928cdb.jpg);
	background-size: cover;
	background-position: 50%;
	background-repeat: no-repeat;
	overflow: hidden;
	opacity: 0.8;
	transition: width 0.4s ease-in-out;
	color: white;
}
