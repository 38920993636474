.myInput {
  border: 1px solid rgba(205, 205, 222, 0.6) !important;
  box-sizing: border-box !important;
  border-radius: 8px !important;
}
.StripeElement {
  border: 1px solid rgba(205, 205, 222, 0.6) !important;
  box-sizing: border-box !important;
  border-radius: 8px !important;
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.5;
  color: #7b8a8b;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.StripeElement--invalid {
  border: 1px solid red;
}

.myButton {
  background: linear-gradient(90.25deg, #998bdf 1.63%, #c8a7b6 99.9%);
  border-radius: 8px !important;
  font-family: Sofia Pro;
  font-style: normal;
  font-weight: 600 !important;
  font-size: 14px !important;
  color: #ffffff !important;
}

.mycard {
  background: #ffffff;
  box-shadow: 0px 6px 50px rgba(17, 20, 47, 0.05);
  border-radius: 10px;
}
.myOrderText {
  font-family: sofia-pro, sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 20px;

  color: rgba(17, 20, 47, 0.6);
}
.myOrderText1 {
  font-family: sofia-pro, sans-serif;
  font-style: normal;
  font-size: 14px;
  line-height: 22px;
  color: #11142f;
}

.vl {
  border-left: 4px solid rgba(23, 29, 82, 0.6);
  height: 90%;
}

.myOrderSummary {
  font-family: sofia-pro, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 14px;
}

.orderGuaranteeText {
  font-family: sofia-pro, sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 20px;

  /* or 167% */

  color: rgba(17, 20, 47, 0.6);
}
.orderGuarantee {
  font-family: sofia-pro, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 14px;

  /* identical to box height */

  color: #11142f;
}

.title {
  font-family: sofia-pro, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 28px;

  /* identical to box height */

  color: #11142f;
}
/* .myIcon {
    position: absolute;
    width: 9.86px;
    height: 7.59px;
    left: 467.31px;
    top: 1634.52px;
    
    border: 1.2px solid #857FF1;
  } */

.billingText {
  font-family: sofia-pro, sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 20px;

  /* or 167% */

  color: rgba(47, 24, 17, 0.6);
}

.billingCheckbox {
  background: linear-gradient(90.25deg, #998bdf 1.63%, #c8a7b6 99.9%);
  border-radius: 6px;
  border: 1.5px solid #ffffff;
}

.billingCheckboxText {
  font-family: sofia-pro, sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 20px;
  color: #11142f;
}

.nav-custom {
  background: rgba(205, 205, 222, 0.3);
  border-radius: 8px;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #857ff1 !important;
  background-color: #fff !important;
  /* margin: 5px; */
}

.signup-terms-text {
  font-family: sofia-pro, sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 20px;
  /* or 167% */
  color: #11142f;
}

.navbar-custom {
  background-color: #11142f;
  position: sticky !important;
  top: 0;
  z-index: 1071;
}
.navbar-brand {
  margin-left: 90px;
}

.errorMessage {
  color: rgba(223, 60, 11, 0.6);
  font-family: sofia-pro, sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
}

.container-checkbox {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 12px;
  cursor: pointer;
  font-family: sofia-pro, sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 20px;
  /* or 167% */
  color: #11142f;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 6px;
  margin-top: 2px;
  width: 20px;
  height: 20px;
  border: 1px solid rgba(205, 205, 222, 0.6);
}

/* On mouse-over, add a grey background color */
.container-checkbox:hover input ~ .checkmark {
  margin-top: 2px;
  border: 1px solid rgba(205, 205, 222, 0.6);
  box-sizing: border-box;
  border-radius: 6px;
  width: 20px;
  height: 20px;
}

/* When the checkbox is checked, add a blue background */
.container-checkbox input:checked ~ .checkmark {
  margin-top: 2px;
  position: absolute;
  width: 20px;
  height: 20px;

  /* Linear */
  background: linear-gradient(90.25deg, #998bdf 1.63%, #c8a7b6 99.9%);
  border-radius: 6px;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container-checkbox input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container-checkbox .checkmark:after {
  left: 7px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}