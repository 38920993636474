.embedModal {
   display: block !important; /* Hidden by default */
   position: fixed; /* Stay in place */
   z-index: 3; /* Sit on top */
   left: 0;
   top: 0;
   width: 100%; /* Full width */
   height: 100%; /* Full height */
   overflow: auto; /* Enable scroll if needed */
   background-color: rgb(0, 0, 0); /* Fallback color */
   background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

.embedModalContent {
   width: 90%;
   height: 86vh;
   background: #F0EFFF !important;
   padding: 10px 40px !important;
   border-radius: 10px;
   color: #000;
}

.embedModal .modalClose {
   color: #aaa;
   font-size: 28px;
   line-height: 20px;
   font-weight: bold;
   cursor: pointer;
   position: absolute;
   top: 0;
   right: 0;
   margin: 20px !important;
}

.embedModal .text-dark{
   color: #200e32 !important;
   font-weight: 300;
}

.copy-btn{
   position: absolute;
   bottom: 0;
   right: 0;
   border: 1px solid #200e32;
   border-radius: 2px;
   user-select: none;
}

.code-part{
   flex-basis: 50%;
   height: 94%;
   border: 1px solid #cfcfcf;
   background-color: #fff;
   position: relative;
   overflow-x: hidden;
}

.code-part pre{
   height: 100%;
   padding: 0 10px;
   overflow-y: scroll;
   margin: 0 !important;
}

.select-styles{
   display: flex;
   align-items: center;
   column-gap: 30px;
   margin-bottom: 20px;
}

.select-styles .option{
   display: flex;
   align-items: center;
   column-gap: 6px;
   cursor: pointer;
}

.select-styles .option label{
   margin-bottom: 0 !important;
   color: #000;
}

.show-btn{
   cursor: pointer;
   border: 1px solid #200e32;
   padding: 2px 4px;
   border-radius: 2px;
   user-select: none;
}

.select-styles input{
   outline: 0;
   font-size: 14px;
}

.preview-container{
   flex-basis: 50%;
   text-align: center;
   font-weight: bold;
   font-size: 18px;
   border: 1px solid #cfcfcf;
   padding: 16px;
   height: 94%;
}

