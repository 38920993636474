.v2-mainWidth{
   margin-left: 0 !important;
}



.imgPreview {
   text-align: 'center';
   margin: 5px 15px;
   /* height: 200px;
   width: 200px; */
   /* border-left: 1px solid gray;
   border-right: 1px solid gray;
   border-top: 5px solid gray;
   border-bottom: 5px solid gray; */
}

.imgPreview img {
   width: 200px;
   height: 200px;
   margin-right: 10px;

   object-fit: contain;
   background-color: rgba(0, 0, 0, 0.3);
}

/*Once remove MaterializeCSS you can remove the first 3*/
.selectImgCheckbox {
   opacity: 1 !important;
   pointer-events: inherit !important;
   position: relative !important;
   /* top: 50px;
   left: 120px; */
   width: 17px;
   height: 17px;
   top: 0.5px;
   left: -8.5px;
}

.pageNumbers {
   display: inline-block;
   margin-top: 0!important;
}

.pageNumbers span {
   color: black;
   float: left;
   padding: 8px 16px;
   text-decoration: none;
   cursor: pointer;
}

.pageNumbers span.currentPage {
   background-color: #4CAF50;
   color: white;
}

.pageNumbers span:hover:not(.currentPage) {background-color: #ddd;}

.uploadButton {
   position: relative;
   top: 20px;
}

/* .userImageContainer {
   width: 78%;
   margin: 0 auto;
   height: auto;
   min-height: 300px;
   max-height: 530px;
   overflow-y: auto;
   border: rgba(0, 0, 0, 0.15);
   border-style: solid;
   border-width: 1px;
} */

.userImageContainer {
   min-height: 256px;
   /*max-height: 512px;*/
   max-height: 100%;
   overflow-y: auto;
}

/* .userImageCard {
   width: 130px;
   height: 130px;
   border: rgba(0, 0, 0, 0.15);
   border-style: solid;
   border-width: 1px;

   object-fit: contain;
   background: rgba(0, 0, 0, 0.3);
} */

.userImageCard {
   width: 100%;
   height: 100%;
   object-fit: cover;
   image-orientation: from-image;
}

.uploadOptionContainer {
   width: 78%;
   min-height: 75px;
   margin: 0 auto;
   background-color: rgba(0, 0, 0, 0.05);
   margin-top: 10px;
}

.uploadOptionText {
   display: inline-block;
   margin-left: 20px;
   margin: 25px 25px 0 20px;
   color: darkgrey;
}

.modalContainer {
   display: none; /* Hidden by default */
   position: fixed; /* Stay in place */
   z-index: 3; /* Sit on top */
   padding-top: 100px;
   left: 0;
   top: 0;
   width: 100%; /* Full width */
   height: 100%; /* Full height */
   overflow: auto; /* Enable scroll if needed */
   background-color: rgb(0, 0, 0); /* Fallback color */
   background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* Modal Content/Box */
.uploadModalContent,
.stmtModalContent {
   background-color: #fefefe;
   margin: auto;
   position: relative;
   padding: 20px;
   border: 1px solid #888;
   width: 80%; /* Could be more or less, depending on screen size */
   /* -webkit-animation-name: animatetop;
   -webkit-animation-duration: 0.4s;
   animation-name: animatetop;
   animation-duration: 0.4s; */
}

.pp-animate-top {
   position: relative;
   animation: animatetop 0.4s;
}

.pp-animate-bottom {
   position: relative;
   animation: animatebottom 0.4s;
}

/* Add Animation */
@keyframes animatetop {
   from {
      top: -300px;
      opacity: 0;
   }
   to {
      top: 0;
      opacity: 1;
   }
}

@keyframes animatebottom {
   from {
      bottom: -300px;
      opacity: 0;
   }
   to {
      bottom: 0;
      opacity: 1;
   }
}

/* The Close Button */
.close {
   color: #aaa;
   float: right;
   font-size: 28px;
   font-weight: bold;
}

.close:hover,
.close:focus {
   color: black;
   text-decoration: none;
   cursor: pointer;
}

/* .pp-img-container {
   display: inline-block;
   margin-left: 15px;
} */

.pp-img-container {
   position: relative;
   width: 128px;
   height: 128px;
}

/* .pp-action-container {
   position: relative;
   background-color: rgba(0, 0, 0, 0.3);
   width: 130px;
   top: 130px;
} */

.pp-action-container {
   position: absolute;
   width: 100%;
   bottom: 0;
   background-color: rgba(0, 0, 0, 0.25);
}

.pp-img-trash-icon {
   position: relative;
   color: white;
   left: 110px;
   bottom: 1px;
   display: inline;
}

.pp-stmt-img-card {
   width: 200px;
   height: 160px;
   border-style: solid;
   background-color: white;
   padding: 10px;
   border-color: rgba(0, 0, 0, 0.2);
   border-width: 1px;
   object-fit: cover;
}
