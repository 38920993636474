.fontSmall {
   font-size: 12px;
}

.fontRegular {
   font-size: 14px;
}

.fontLight {
   opacity: 0.5;
}

.fontBold {
   font-weight: bold;
}

.categoryWidth {
   width: 120px;
}

.imageContainer {
   width: 100%;
   height: 100%;
   max-height: 400px;
   object-fit: cover;
}

.customInput {
   max-width: 42px;
}

.customInputYear {
   max-width: 60px;
}
