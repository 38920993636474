.-btn {
    color: white !important;
}

.-sort-desc {
    box-shadow: none !important;
}

.-sort-asc {
    box-shadow: none !important;
}