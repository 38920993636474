.picture-card{
    position: relative;
    height: 100%;
    width: 100%;
    cursor: pointer;
    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(to bottom, rgba(247, 137, 137, 0.25), rgba(0,0,0,0.75));
        border-radius: 10px;
    }
    &__image{
        width: inherit;
        height: 100%;
        border-radius: 10px;
        object-fit: cover;
    }
    &__text{
        position: absolute;
        bottom: 10px;
        margin-left: 24px;
        
    }
}