.react-autosuggest__container {
  position: relative;
  width: 100%;
}

.react-autosuggest__input {
  width: 100%;
  height: 35px;
  padding: 10px 35px;
  font-weight: 300;
  font-size: 16px;
  border-radius: 4px;
  opacity: 0.5;
  border: 0 !important;
  outline: 0;
  font-family: Gotham;
  color: #000 !important;
  letter-spacing: 1px !important;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-bottom: 1px solid #fff !important;
}

.react-autosuggest__input::placeholder {
  color: black;
  opacity: 1;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  width: 100%;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  max-height: 300px;
  overflow-y: auto;
  z-index: 2;
  border: 0 !important;
  outline: 0;
  font-family: Gotham;
  color: #fff !important;
  letter-spacing: 1px !important;
  border: 1px solid #000;
  border-bottom: 1px solid #fff !important;
  background: rgba(0, 0, 0, 0.48);
  mix-blend-mode: normal;
  backdrop-filter: blur(200px);
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
  color: #fff;
  font-family: "sofia-pro, sans-serif";
}

.react-autosuggest__suggestion--highlighted {
  background-color: #fff !important;
  color: #000 !important;
  font-family: "sofia-pro, sans-serif";
}

.searchBar {
  position: relative;
}

.searchBarIcon {
  position: absolute;
  left: 3%;
  top: 28%;
  stroke-width: 3px;
  color: white !important;
}
