.stats-card{
    background: rgba(2, 0, 37, 0.25);
    backdrop-filter: blur(100px);
    padding: 20px !important;
    border-radius: 10px;
    &__main{
        display: flex;
        align-items: center;
        &-image{
            width: 20px;
            margin-right: 12px;
        }
        &-value{
            font-size: 28px;
            font-weight: 250;
            line-height: 1.6;
            margin-right: 6px;
        }
        &-unit{
            font-weight: 100;
            font-size: 16px;

        }
    }
}