.surveyModal {
   display: none; /* Hidden by default */
   position: fixed; /* Stay in place */
   z-index: 3; /* Sit on top */
   left: 0;
   top: 0;
   width: 100%; /* Full width */
   height: 100%; /* Full height */
   overflow: auto; /* Enable scroll if needed */
   background-color: rgb(0, 0, 0); /* Fallback color */
   background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

.surveyModalContent {
   background-color: #fefefe;
}

.modalClose {
   color: #aaa;
   font-size: 28px;
   line-height: 20px;
   font-weight: bold;
   cursor: pointer;
}

.ppEmoji {
   font-size: 64px;
   line-height: 64px;
}

.ppEmojiButton {
   opacity: 0.25;
   max-width: 20vw;
   max-height: 20vw;
}

.ppEmojiButton:hover {
   opacity: 1;
   box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

.ppEmoActive {
   opacity: 1;
   box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

.ppBlue {
   color: #007bff;
}
.ppGreen {
   color: #28a745;
}
.ppOrange {
   color: #fd7e14;
}
.ppRed {
   color: #dc3545;
}
.ppGray {
   color: #868e96;
}
.ppPink {
   color: #e83e8c;
}
