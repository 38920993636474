.error_div {
    background: #6b65f8;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.service_unavailable_div {
    background: #fffef6;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.service_unavailable_first_div {
    margin-top: 100px;
    margin-left: 2%;
    color: black;
}

.service_unavailable_first_div h1{
    font-weight: bolder;
}

.service_unavailable_first_div p{
    font-size: 25px;
    font-style: italic;
}

.home_button {
    border: 2px solid #ffffff;
    display: inline-block;
    background: linear-gradient(104.21deg,#ff6624 -27.11%,#cdaab1 114.9%);
    border-radius: 45px;
    color: #ffffff;
    padding: 20px 50px;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 600;
    -webkit-transition: all 0.5s ease-out;
    -moz-transition: all 0.5s ease-out;
    -ms-transition: all 0.5s ease-out;
    -o-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
    &:hover {
        color: #ffffff;
        background: transparent;
    }
}

.first_div {
    margin-top: 10%;
    @media (min-width:390px) and (max-width:900px) {
        margin-top: 20%;
        margin-left: 3%;
        margin-right: 3%;
    }
    @media (max-width:430px) {
        margin-top: 30%;
        margin-left: 3%;
        margin-right: 3%;
    }
}

.heading {
    font-size: 36px;
    font-weight: 500; 
    @media (max-width:594px) {
        font-size: 28px;
    }
}

.para {
    font-size: 24px;
    font-weight: 400;
    @media (max-width:594px) {
        font-size: 15px;
    }
}