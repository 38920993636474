.x-btn{
    border-radius: 25px;
    color: #fff;
    padding: 8px 24px;
    font-weight: 300;
    &:hover{
        color: #fff;
    }
}

.x-primary{
    background: linear-gradient(92.59deg, #857FF1 -19.68%, #857FF1 117.55%);
}

.x-secondary{
    background: linear-gradient(104.21deg, #FF6624 -27.11%, #CDAAB1 114.9%);
}

.faIconButton{
    cursor: pointer;
    background: rgba(2, 0, 37, 0.25);
    backdrop-filter: blur(100px);
    border-radius: 6px;
    padding: 2px 8px;
    font-size: 18px;
}

@media (min-width:1024px){.sidebarSection__closeIcon2{display:none}}
@media (min-width:1024px){.appbar__hamburgerIcon2{display:none}}

.sideBarSectionWidth{
    width: 13%;
    margin-left: 83%;
}

.appBarHeight{
    height: 100%;
}