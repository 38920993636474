.favorite_button {
    position: relative;
    animation: pulse 0.4s ease;
    animation-iteration-count: 1;
}

.favorite_button_hollow {
    position: relative;
    animation: pulse 0.4s ease;
    animation-iteration-count: 1;
}
  
  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.3);
    }
    100% {
      transform: scale(1);
    }
  }