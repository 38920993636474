$bargraph-container-height: 360px;
$bargraph-container-height-mobile: 260px;



.dashboardViewCol{
    padding: 0;
    margin: 8px 0;
    & .container{
        padding: 0;
    }
    &:not(:last-child){
        padding-right: 12px;
       
    }

    @media (max-width: $breakpoint-mobile) {
        padding-right: 12px;
        padding-left: 12px;
    }

    & .statContainer{
        margin: 12px 0;
        padding: 0;
        &:first-child{
            margin-top: 0;
        }
    }

}

.chart-and-sod{
    display: flex;
    flex-direction: column;
    &__chart{
        margin-bottom: 12px;
        height: $bargraph-container-height;
        @media (max-width: $breakpoint-mobile) {
            height: $bargraph-container-height-mobile;
        }
    }
    &__sod{
        height: calc(100% - 432px);
        @media (max-width: $breakpoint-mobile) {
            height: auto;
        }
    }
}

.featured_session{
    height: 216px;
    border-radius: 10px;
}

