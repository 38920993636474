.outer_div_box {
    border: 1px dashed #7255a9;
}

.outer_div_box_heading {
    margin-top: -11px;
    background: #f2f2f2;
    width: fit-content;
    margin-left: 3%;
    font-weight: bold;
}

.outer_div_box1 {
    border: 1px dashed #ed145b;
}

.outer_div_box_heading1 {
    margin-top: -11px;
    background: #f2f2f2;
    width: fit-content;
    margin-left: 3%;
    font-weight: bold;
}

.bold_perk_text {
    color: #7255a9;
}

.container {
    @media (max-width: 767px) {
        margin: 0 !important;
        padding: 0 !important;
        max-width: 100% !important;
    }
}

.main_row {
    @media (max-width: 767px) {
        width: 100% !important;
    }
}

.signup-button-css {
    background: #11142f !important;
    border-radius: 8px !important;
    font-family: Sofia Pro !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 14px !important;
    color: #ffffff !important;
}

.signup-button-css:disabled {
    background: #d3d3d8 !important;
    border-radius: 8px !important;
    font-family: Sofia Pro !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 14px !important;
    color: #ffffff !important;
    box-shadow: 0px 0px #ffffff !important;
}

.arrow-right {
    background: #6b65f8;
    font-size: 150%;
    text-align: center;
    color: #fff;
    flex-basis: 30%;
    padding: 2px;

    &::after {
        content: "";
        position: absolute;
        border: 0;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        margin-left: -10px;
        left: 80%;
        border-bottom: 10px solid #6b65f8;
        top: -10px;
    }
}
.arrow-center {
    background: #6b65f8;
    font-size: 150%;
    text-align: center;
    color: #fff;
    flex-basis: 30%;
    padding: 2px;

    &::after {
        content: "";
        position: absolute;
        border: 0;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        margin-left: -10px;
        left: 50%;
        border-bottom: 10px solid #6b65f8;
        top: -10px;
    }
}
.arrow-left {
    background: #6b65f8;
    font-size: 150%;
    text-align: center;
    color: #fff;
    flex-basis: 30%;
    padding: 2px;

    &::after {
        content: "";
        position: absolute;
        border: 0;
        z-index: 1;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        margin-left: -10px;
        left: 20%;
        border-bottom: 10px solid #6b65f8;
        top: -10px;
    }
}

.column_border {
    border-top: 0.3px solid #e0e0e4;
    border-right: 0.3px solid #e0e0e4;
    border-left: 0.3px solid #e0e0e4;
}

.text_color {
    color: #6b65f8;
}

.check_radio {
    border-radius: 6px;
    margin-top: 2px;
    width: 15px;
    height: 15px;
    border: 1px solid rgba(0, 0, 248, 0.6);
  }