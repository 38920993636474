.image-player {
   margin: 20px 0px;
   text-align: center;
}

.image-title {
   margin: 20px 0px;
   font-weight: bold;
}

#mainImagePlayer img {
   /* max-width: 768px;
   max-height: 460px;
   width: 100%;
   height: 100%; */
   width: calc(100vw - 2rem);
   height: calc((100vw - 2rem) * (9 / 16));
   object-fit: contain;
   background-color: black;
   image-orientation: from-image;
}

/* @media (min-width: 0px) {
   #mainImagePlayer img {
      width: 256px;
      height: 144px;
   }
} */

@media (min-width: 576px) {
   #mainImagePlayer img {
      width: 384px;
      height: 216px;
      image-orientation: from-image;
   }
}

@media (min-width: 768px) {
   #mainImagePlayer img {
      width: 512px;
      height: 288px;
      image-orientation: from-image;
   }
}

@media (min-width: 992px) {
   #mainImagePlayer img {
      width: 640px;
      height: 360px;
      image-orientation: from-image;
   }
}

@media (min-width: 1200px) {
   #mainImagePlayer img {
      width: 768px;
      height: 432px;
      image-orientation: from-image;
   }
}

/* The Modal (background) */
/* .modal {
   display: none;
   position: fixed;
   z-index: 1;
   left: 0;
   top: 0;
   width: 100%;
   height: 100%;
   margin: 0;
   max-height: inherit;
   overflow: auto;
   background-color: rgba(0, 0, 0, 0.75);
} */

/* Modal Content */
/* @media screen and (orientation:portrait) {
   #modalImagePlayer {
       width: 100vh;
       height: 100vw;
       transform-origin: bottom left;
       margin-top: -100vw;
       transform: rotate(90deg);
   }
}

@media screen and (orientation:landscape) {
   #modalImagePlayer {
       width: 100vw;
       height: 100vh;
   }
} */

#modalImagePlayer {
   display: none;
   position: absolute;
   top: 0;
   left: 0;
   width: 100vw;
   height: 100vh;
   z-index: 2;
   background-color: black;
}

#modalImagePlayerContent {
   width: 100%;
   height: 100%;
   position: relative;
   overflow: hidden;
}

#modalImagePlayerImage {
   width: 100%;
   height: 100%;
   /* height: calc(100vw * (9 / 16)); */
   /* position: absolute; */
   /* top: 50%; */
   /* transform: translate(0, -50%); */
}

/* #modalImagePlayerContent, #modalImagePlayerImage {
   width: 100%;
   height: 100%;
} */

#modalImagePlayerImage img {
   width: 100%;
   height: 100%;
   object-fit: contain;
}

@media screen and (orientation: portrait) {
   #modalImagePlayerImage {
      height: inherit;
   }
}

.modal-image {
   position: fixed;
   /* top: 0; 
  left: 0; 
  bottom: 0;
  right:0; */

   /* Preserve aspet ratio */
   min-width: 100%;
   min-height: 100%;
}

/* The Close Button */
#modalImagePlayer .modal-close {
   color: white;
   position: absolute;
   top: 10px;
   right: 25px;
   font-size: 40px;
   font-weight: bold;
}

#modalImagePlayer .modal-close:hover,
#modalImagePlayer .modal-close:focus {
   color: #999;
   text-decoration: none;
   cursor: pointer;
}

#modalImagePlayer span.cursor {
   cursor: pointer;
}

/* Add Animation */
.modal-content,
#caption {
   -webkit-animation-name: zoom;
   -webkit-animation-duration: 0.3s;
   animation-name: zoom;
   animation-duration: 0.3s;
}

@-webkit-keyframes zoom {
   from {
      -webkit-transform: scale(0);
   }
   to {
      -webkit-transform: scale(1);
   }
}

@keyframes zoom {
   from {
      transform: scale(0);
   }
   to {
      transform: scale(1);
   }
}
/* End Add Animation */

.player-control {
   /* background-color: rgba(105, 105, 105, 0.5); */
   background-color: rgba(0, 0, 0, 0.5);
   /* position: relative; */
   position: absolute;
   transition: 0.6s ease;
   width: 100%;
   /* max-width: 760px; */
   /* display: inline-block; */
   /* text-align: center; */
   /* white-space: nowrap; */
   /* vertical-align: middle; */
   /* bottom: 50px; */
   bottom: 0;
   height: 50px;
   visibility: visible;
   opacity: 0;
   transition: visibility 0.5s, opacity 0.5s linear;
}

.image-container:hover .player-control {
   visibility: visible;
   opacity: 1;
}

.modal-player-control {
   background-color: rgba(0, 0, 0, 0.5);
   position: absolute;
   /* transition: 0.6s ease; */
   width: 100%;
   /* max-height: 50px; */
   height: 50px;
   /* max-height: 700px;
   max-width: 1198px; */
   /* display: inline-block; */
   /* text-align: center; */
   /* white-space: nowrap; */
   /* vertical-align: middle; */
   bottom: 0px;
   /* transform: translate(0%,1300%); */

   visibility: hidden;
   opacity: 0;
   transition: visibility 0.5s, opacity 0.5s linear;
}

#modalImagePlayerContent:hover .modal-player-control {
   visibility: visible;
   opacity: 1;
}

.white {
   color: white;
}

.white-text {
   color: white;
   background-color: rgba(105, 105, 105, 0);
}

.left {
   position: absolute;
   left: 0px;
   margin-left: 20px;
}

.right {
   position: absolute;
   right: 0px;
   margin-right: 20px;
}

.sm-font {
   font-size: 0.5rem;
   cursor: pointer;
}

.md-font {
   font-size: 0.75rem;
   cursor: pointer;
}

@media (min-width: 576px) {
   .sm-font {
      font-size: 1rem;
   }
   .md-font {
      font-size: 1.5rem;
   }
}

.top-play-grp,
.top-expand-grp {
   margin-top: 6px;
}

.top-speed-grp {
   margin-top: 6px;
}

.left-speed-down {
   position: absolute;
   left: 1.5em;
   margin-left: 20px;
}

.left-speed-up {
   position: absolute;
   left: 3em;
   margin-left: 10px;
}

.left-audio {
   position: absolute;
   left: 4.1em;
   margin-left: 10px;
}

.speed-txt {
   position: absolute;
   left: 3em;
   font-weight: bold;
   margin-left: 100px;
   margin-top: 14px;
   cursor: default;
}

.time-remain-txt {
   position: absolute;
   left: 2.5em;
   font-weight: bold;
   margin-left: 140px;
   margin-top: 14px;
   cursor: default;
}

.hidden {
   display: none;
}

.image-title-detail {
   position: absolute;
   top: 76px;
   left: 50%;
   background-color: rgba(105, 105, 105, 0.5);
   transform: translate(-50%, -50%);
   padding: 0.01em 16px;
}

.modal-image-title-detail {
   position: absolute;
   top: 12px;
   left: 50%;
   background-color: rgba(105, 105, 105, 0.5);
   transform: translate(-50%, -50%);
   padding: 0.01em 16px;
}

.white:active {
   color: rgba(0, 191, 255, 1);
}

.disabled {
   color: gray !important;
   cursor: default !important;
   stroke: white;
   stroke-width: 6;
}

.pp-img-disable {
   display: none;
}
