
.loginSplash {
  background: url(../assets/splash2.jpg) cover !important;
  background-image: url(../assets/splash2.jpg) !important;
  background-size: cover !important;
  background-position: center center !important;
}

.btn-facebook {
  @include button-variant($facebook, $facebook);
}


.loginForm {
  min-width: 60%;
}

.processingOverlay {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  margin: 0;
  padding: 0;
  width: 100%;
  z-index: 100;
  background-color: rgba($primary, .9);
  color: $white;
}
