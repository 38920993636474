.appbar{
    display: flex;
    width: 100%;
    align-items: center;
    font-size: 13px;
    font-weight: 250;
    letter-spacing: .6px;
    &__quote{
        flex: 1;
        display: flex;
        align-items: center;
        &-icon{
            width: 50px;
            margin-right: 16px;
        }
        &-text{
            margin: 0;
        }
        @media (max-width:$breakpoint-mobile) {
            display: none;
        }
    }
    &__hamburgerIcon{
        
        @media (min-width:$breakpoint-mobile) {
            display: none;
        }
    }
    &__user{
        flex: 0 0 140px;
        justify-self: flex-end;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        &-photo{
            width: 30px;
            height: 30px;
            border-radius: 50%;
            margin-right: 8px;
        }
        @media (max-width:$breakpoint-mobile) {
            flex: 1;
        }
    }
}