#nav-tab a {
   color: rgba(0, 0, 0, 0.5);
}

#nav-tab a:hover {
   color: #007bff;
   background-color: inherit;
}

#nav-tab .active {
   color: #007bff;
   font-weight: bold;
   border-bottom: 2px solid #007bff;
}

.featuredImageContainer {
   height: 200px;
}

.imageContainer {
   width: 100%;
   height: 100%;
   object-fit: cover;
}

.featureMaxHeight {
   max-height: 224px !important;
}

.grayscale {
   filter: grayscale(100%);
}

.overlay {
   position: absolute;
   top: 0;
   bottom: 0;
   right: 0;
   left: 0;
   width: 100%;
   height: 100%;
   opacity: 0.75;
}

.overlayBlue {
   background-color: #007bff;
}
.overlayGreen {
   background-color: #28a745;
}
.overlayOrange {
   background-color: #fd7e14;
}
.overlayRed {
   background-color: #dc3545;
}

.imageCaption {
   position: absolute;
   top: 50%;
   transform: translateY(-50%);
}

.imageCaptionTitleFeat {
   font-size: 20px;
   line-height: 24px;
}

.imageCaptionDescFeat {
   font-size: 14px;
   opacity: 0.75;
}

.pp-block-with-text {
   /* autoprefixer: off */
   -webkit-box-orient: vertical;
   /* autoprefixer: on */
   display: block;
   display: -webkit-box;
   height: 58.8px; /* line-height*clamp*font-size 1.4*3*14 */
   line-height: 1.4em;
   -webkit-line-clamp: 3;
   overflow: hidden;
   text-overflow: ellipsis;
}

.advertisement {
   width: 100%
}