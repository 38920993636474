.spinner > div {
    width: 10px;
    height: 10px;
    background-color: #11142f;

    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
}

.spinner .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
        -webkit-transform: scale(0);
    }
    40% {
        -webkit-transform: scale(1);
    }
}

@keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    40% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

.signup-button-css {
    background: #11142f !important;
    border-radius: 8px !important;
    font-family: Sofia Pro !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 14px !important;
    color: #ffffff !important;
}

.signup-button-css:disabled {
    background: #d3d3d8 !important;
    border-radius: 8px !important;
    font-family: Sofia Pro !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 14px !important;
    color: #ffffff !important;
    box-shadow: 0px 0px #ffffff !important;
}
