.setting-card {
  background: rgba(0, 0, 0, 0.48);
  mix-blend-mode: normal;
  backdrop-filter: blur(200px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 15px;
}
.setting-card-header {
  padding: 5px;
}
.setting-img {
  height: 150px !important;
  max-width: 165px !important;
  max-height: 150px !important;
}
.setting-button {
  background: linear-gradient(
    104.21deg,
    #857ff1 -27.11%,
    #cdaab1 114.9%
  ) !important;
  border-radius: 86px !important;
  font-size: 16px;

  color: #ffffff;

  mix-blend-mode: normal;
  opacity: 0.8;
}

.setting-input {
  color: rgb(0, 0, 0) !important;
  background-color: white !important;
  opacity: 0.5;
  border-radius: 5px;
}

.send-invite-button{
  width: 40%;
}
