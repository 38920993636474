.screenshot-imageContainer {
   width: 100%;
   /* height: 100%; */
   height: calc((100vw / 3) * (9 / 16));
   object-fit: cover;
   /* max-height: 320px; */
}

@media (min-width: 768px) {
   .screenshot-imageContainer {
      /* height: 128px; */
      height: calc(((100vw - 240px) / 3) * (9 / 16));
   }
}

/* @media (min-width: 768px) {
   .screenshot-imageContainer {
      height: 160px;
   }
}

@media (min-width: 992px) {
   .screenshot-imageContainer {
      height: 192px;
   }
} */

.grayscale {
   filter: grayscale(100%);
}

.overlay {
   position: absolute;
   top: 0;
   bottom: 0;
   right: 0;
   left: 0;
   width: 100%;
   height: 100%;
   opacity: 0.65;
}

.overlayBlue {
   background-color: #007bff;
}
.overlayGreen {
   background-color: #28a745;
}
.overlayOrange {
   background-color: #fd7e14;
}
.overlayRed {
   background-color: #dc3545;
}

.imageCaption {
   position: absolute;
   top: 50%;
   transform: translateY(-50%);
}

.imageCaptionTitle {
   font-size: 32px;
}

.imageCaptionDesc {
   font-size: 16px;
   opacity: 0.7;
}

.searchAll {
   width: auto;
   border-right: 0;
   border-top-right-radius: 0;
   border-bottom-right-radius: 0;
}

#searchBar {
   border-radius: 0;
}

#searchButton {
   border-top-left-radius: 0;
   border-bottom-left-radius: 0;
}

.filterAll {
   width: auto;
}

.imageSessionAuthor {
   width: 92px;
   height: 92px;
   object-fit: cover;

   border: 4px solid white;
}

.carousel-control-prev,
.carousel-control-next {
   width: 90px;
}

.purchaseModal {
   display: none; /* Hidden by default */
   position: fixed; /* Stay in place */
   z-index: 3; /* Sit on top */
   padding-top: 300px;
   left: 0;
   top: 0;
   width: 100%; /* Full width */
   height: 100%; /* Full height */
   overflow: auto; /* Enable scroll if needed */
   background-color: rgb(0, 0, 0); /* Fallback color */
   background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

.purchaseModalContent {
   background-color: #fefefe;
   margin: auto;
   position: relative;
   padding: 20px;
   border: 1px solid #888;
   width: 30%; /* Could be more or less, depending on screen size */
   /* -webkit-animation-name: animatetop;
   -webkit-animation-duration: 0.4s;
   animation-name: animatetop;
   animation-duration: 0.4s; */
}
