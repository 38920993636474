.editModal {
    display: block !important; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 3; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
 }
 
 .editModalContent {
    width: 500px;
    background: #F0EFFF !important;
    padding: 10px 40px !important;
    border-radius: 10px;
    color: #000;
 }
 
 .editModal .modalClose {
    color: #aaa;
    font-size: 28px;
    line-height: 20px;
    font-weight: bold;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    margin: 20px !important;
 }
 
 .editModal .text-dark{
    color: #200e32 !important;
    font-weight: 300;
 }

.editModal .MuiTypography-body1{
    color: #200e32;
}

.edit-field label{
   margin: 10px 0 0 0;
}

.editModal .date-picker{
   border: 1px solid #ced4da;
}

 
 
 