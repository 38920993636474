$img-box-dim: 140px;

.x-modal{
    &__content{
        background: #F0EFFF;
        padding: 30px;
    }
    &__header{
        &-subtitle{
            max-width: 550px;
            text-overflow: clip;
        }
    }
}

.ps-Modal{
    & > div{
        margin-bottom: 30px;
    }
    & > * {
        color: rgb(0, 0, 0);
    }
    &__newImages{
        &-title{
            margin-bottom: 16px;
        }
        &-container{
            background: rgba(2, 0, 37, 0.1);
            opacity: 0.8;
            padding: 25px;
            border-radius: 19px;
        }
    }
    &__ImageBoxes{
        padding: 20px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        border-radius: 15px;
        background: rgba(55, 55, 61, 0.1);
        align-content: center;
        justify-content: space-evenly;
        &-upload{
            display: flex;
            justify-content:center;
            flex-direction: column;
            align-items:center;
            cursor: pointer;
            & > p{
                margin-bottom: 0 !important;
            }
        }
        &  .img-box{
            margin-top: 10px;
            padding: 8px;
            border: 1.5px solid #eee;

            border-radius: 10px;
            background: rgba(55, 55, 61, 0.1);
            width: $img-box-dim;
            height: $img-box-dim;
        }
    }

    &__selectImages{
        &-header{
            display: flex;
            align-items: center;
            margin-bottom: 16px;

        }
        &-btns{
            align-self: flex-end;
            &>button{
                margin-left: 16px;
            }
        }
        &-title{
            flex: 1;

        }
        &-container{
            display: flex;
            align-items: center;
            flex-direction: row;
            flex-wrap: wrap;
            & > div{
               
                    margin-left: 24px;
            }
        }
    }

    &__footer{
        display: flex;
        align-items: center;
        justify-content: center;
    }
}