.custom-desc{
    display: inline-block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 1.75rem 0.375rem 0.75rem;
    font-size: 0.9375rem;
    font-weight: 400;
    line-height: 1.5;
    color: #7b8a8b;
    vertical-align: middle;
    background: #fff;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    box-shadow: inset 0 1px 2px rgb(0 0 0 / 8%);
    -webkit-appearance: none;
    appearance: none;
    outline: 0;
    height: 89px;
    resize: unset;
}

.custom-url{
    display: inline-block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 1.75rem 0.375rem 0.75rem;
    font-size: 0.9375rem;
    font-weight: 400;
    line-height: 1.5;
    color: #7b8a8b;
    vertical-align: middle;
    background: #fff;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    box-shadow: inset 0 1px 2px rgb(0 0 0 / 8%);
    -webkit-appearance: none;
    appearance: none;
    outline: 0;
    resize: unset;
}

::placeholder {
    color: #7b8a8b;
    opacity: 1;
  }


.embed-code{
    overflow-y: unset !important;
}


.embed-code-container{
    overflow-y: unset !important;
}

.MuiDialog-paper {
    margin: 32px;
    position: relative;
    overflow-y: unset !important;
}

.date-picker{
    background-color: #fff;
    padding: 0 10px;
    border-radius: 0.25rem;
    height: 36.5px
}

.date-input{
    margin-top: 3px !important;
}

.date-input input{
    color: #7b8a8b;
    font-size: 0.9375rem
}

.date-input .MuiButtonBase-root{
    padding: 0;
}

.MuiInput-underline:before{
    border-bottom: 0 solid rgba(0, 0, 0, 0.42) !important
}

.MuiInput-underline:after{
    border-bottom: 0 solid rgba(0, 0, 0, 0.42) !important
}

.MuiTypography-body1{
    font-family: sofia-pro, sans-serif !important;
    font-style: normal;
    font-weight: 600 !important;
    font-size: 14px !important;
    color: #fff;
    line-height: 16px !important;
}

.WithStyles\(ForwardRef\(Switch\)\)-switchBase-3{
    color: grey !important;
}

.WithStyles\(ForwardRef\(Switch\)\)-switchBase-3.WithStyles\(ForwardRef\(Switch\)\)-checked-4 {
    color: #1b5e20 !important;
}


.indicator{
    display: flex;
    align-items: center;
    column-gap: 8px;
}

.indicator p{
    margin-bottom: 4px;
}

.indicator .svg-icon{
    cursor: pointer;
}

.progress-bar{
    width: 100%;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent !important;
}

.playProgress {
    height: 0.6rem !important;
}

.search-field{
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.search-field .search{
    background-color: #ffffff73;
    border-bottom: 1px solid #fff !important;
    border-radius: 4px;
    padding: 4px 10px;
}

.search-field .search-svg{
    color: grey !important;
    font-size: 22px !important;
}

.search-field input{
    outline: 0;
    background-color: transparent;
    width: 260px;
    font-weight: 300;
    font-size: 14px;
    opacity: 0.5;
    border: 0 !important;
    outline: 0;
    color: #000 !important;
    letter-spacing: 1px !important;
    transition: 0.5s;
}

.search-field input:focus{
    width: 400px;
}

.search-field input::placeholder{
    color: #696969 !important;
}

.created-table{
    margin-top: 20px;
    width: 100%;
}

.created-table tr{
    display: flex;
    align-items: center;
    
}

.created-table tr td,
.created-table tr th{
    text-align: center;
}

.created-table tr td{
    margin: 10px 0;
    cursor: pointer;
    background-color: #ffffff18;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
}

.created-table .session-name{
    overflow-wrap: break-word;
    margin-bottom: 0;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.created-table tr td:first-child,
.created-table tr th:first-child{
    flex-basis: 16%;
}

.created-table tr td:last-child,
.created-table tr th:last-child{
    flex-basis: 16%;
    padding-right: 10px;
}

.created-table tr td:first-child{
    justify-content: left !important;
    padding-left: 10px;
}

.created-table .little{
    flex-basis: 8% !important;
}


.generate-div{
    /* background-color: #1b5e20; */
    padding: 6px;
    border: 1px solid #ffffff60;
    color: #ffffff60;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    transition: 0.5s;
}

.generate-div:hover{
    border: 1px solid #ffffff;
    color: #ffffff;
}


.code-field{
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
    column-gap: 14px;
    height: calc( 100% - 75px - 28px - 20px);
}

.embed-code .MuiFormGroup-root{
    flex-direction: row;
}

.embed-code .MuiTypography-body1{
    color: #000;
}

.text-field{
    flex-basis: 50%;
}


.embed-code .MuiOutlinedInput-inputMultiline {
    padding: 0;
    height: 100% !important;
}

.embed-code .MuiOutlinedInput-multiline {
    padding: 18.5px 14px;
    height: 451px;
}
.created-table .url{
    flex-basis: 36%;
    width: 20px;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.created-table td.url{
    padding-top: 14px
}

.created-table .no-res{
    width: 100%;
    text-align: center;
    background-color: #ffffff36;
    padding: 10px;
    border-radius: 4px;
}
