// Collection Page
.wellness-collection{
    &__header{
        margin-bottom: 24px;
    }
}

// Play View

.wellness-play{
   &__main{
       &-personalizeButton{
           margin-top: 32px;
           margin-bottom: 8px;
           display: flex;
           justify-content: flex-end;
       }
       &-upNextContainer{
           margin-bottom: 24px;
       }
       &-upnextList{
           margin-top: 24px;
       }
   }
}

.wellness-session{
    &__header{
        margin-bottom: 24px;
    }
    & .container{
        padding: 0;
    }
    &__author{
        margin-top: 32px;
        margin-bottom: 32px;
        padding: 0 32px !important;
        display: flex;
        align-items: flex-start;
        &-img{
            margin-right: 32px;
            width: 48px;
            height: 48px;
            border-radius: 50%;
        }
    }

    &__moreLikeThese{
        &-title{
            margin-bottom: 32px;
        }
    }
}